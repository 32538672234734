<template>
  <div class="ingredients">
    <div class="page-header">
      <h1>{{ $t('Inventory Management') }} > {{ $t('Inventory') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Inventory') }}</h2>
        <router-link :to="{ name: 'AddIngredient' }">
          <el-button class="action-button" type="primary">{{ $t('Add Ingredient') }}</el-button>
        </router-link>
      </div>
      <ItemFilter :placeholder="$t('Search by ingredient name')" default="ingredientName" :selection="false" @search="searchItems">
        <el-option label="Ingredient Name Search" value="ingredientName"></el-option>
      </ItemFilter>

      <div class="inventory-list">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{ $t('Photo') }}</th>
              <th scope="col">{{ $t('Ingredient Name')}}</th>
              <th scope="col">{{ $t('Categories') }}</th>
              <th scope="col">{{ $t('Quantity') }}</th>
              <th scope="col">{{ $t('Update Quantity') }}</th>
            </tr>
          </thead>
          <tbody v-for="(ingredient, ingredientIndex) in ingredients">
            <tr>
              <td class="ingredient-image-td">
                <div class="ingredient-image-wrapper">
                  <img class="ingredient-image img-fluid" v-if="ingredient.image" :src="ingredient.image" />
                  <img class="ingredient-image img-fluid" v-else src="@/assets/no-image.jpg" />
                </div>
              </td>
              <td>{{ ingredient.name }} </td>
              <td>{{ getIngredientCategoires(ingredient) }}</td>
              <td>{{ ingredient.stock }}</td>
              <td>
                <div class="ingredient-quantity-editor">
                  <input type="number" class="form-control" :value="ingredient.stock" :ref="`${ingredient.id}`">
                  <el-button @click="updateIngredientQuantity(ingredient)">{{ $t('Save') }}</el-button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ItemFilter from '@/components/Filter.vue';
import Common from '@/lib/common';
import Ingredient from '@/lib/ingredient';

export default {
  name: 'Inventory',
  components: {
    ItemFilter,
  },
  mounted(){
    this.loadIngredients();
  },
  data(){
    return {
      orignalIngredients: [],
      ingredients: [],
    }
  },
  methods:{
    searchItems(form){
      const searchField = {};
      let newItems = [];
      switch(form.filterTarget){
        case 'ingredientName':
        if(this.langcode === 'zh'){
          searchField['ingredient_name_zh'] = form.filterValue;
        }else{
          searchField['ingredient_name_en'] = form.filterValue;
        }
        newItems = Common.filterItems(searchField, this.orignalIngredients);
        this.ingredients = newItems;
        break;
      }
    },
    async updateIngredientQuantity(ingredient){
      let newQuantity = this.$refs[`${ingredient.id}`][0];
      newQuantity = newQuantity.value;
      const ingredientInfo = this.loadIngredientToForm(ingredient);
      ingredientInfo.quantity = newQuantity;
      await this.updateIngredient(ingredient.id, ingredientInfo);
      //location.reload();
    },
    async updateOption(ingredient, option){
      let newOptionQuantity = this.$refs[`${option.option_name_en}-${ingredient.id}`][0];
      newOptionQuantity = newOptionQuantity.value;
      const ingredientInfo = this.loadIngredientToForm(ingredient);
      for(let i = 0; i < ingredientInfo.variations.length; i++){
        const variation = ingredientInfo.variations[i];
        const targetOptionIndex = variation.options.findIndex(function(item){
          if(option.option_name_en === item.optionNameEn && option.option_name_zh === item.optionNameZh){
            return true;
          }
        });
        if(targetOptionIndex !==  -1){
          //Update value
          ingredientInfo.variations[i]['options'][targetOptionIndex]['optionQuantity'] = newOptionQuantity;
        }
      }
      await this.updateIngredient(ingredient.id, ingredientInfo);
      location.reload();
    },
    async updateIngredient(ingredientId, ingredientInfo){
      try{
        const loginInfo = Common.getLoginInfo();
        const ingredients = await Ingredient.updateIngredient(this.apiUrl, ingredientId, ingredientInfo, loginInfo);
      }catch(err){
        console.log(err);
      }
    },
    loadIngredientToForm(ingredient){
      const ingredientInfo = {
        categories: [],
        ingredientNameZh: ingredient.ingredient_name_zh,
        ingredientNameEn: ingredient.ingredient_name_en,
        ingredientDescriptionZh: ingredient.ingredient_description_zh,
        ingredientDescriptionEn: ingredient.ingredient_description_en,
        ingredientImages: [],
        unlimitedQuantity: false,
        quantity: ingredient.ingredient_quantity,
        regularPrice: ingredient.ingredient_regular_price,
        variations: [],
      };

      for(let i = 0 ; i < ingredient.ingredient_categories.length; i++){
        ingredientInfo.categories.push(ingredient.ingredient_categories[i].id);
      }

      if(ingredient.ingredient_published == 1){
        ingredientInfo.published = true;
      }else{
        ingredientInfo.published = false;
      }
      if(!ingredient.ingredient_quantity){
        ingredientInfo.unlimitedQuantity = true;
      }
      if(ingredient.ingredient_published == 1){
        ingredientInfo.published = true;
      }else{
        ingredientInfo.published = false;
      }

      if(!ingredient.ingredient_quantity){
        ingredientInfo.unlimitedQuantity = true;
      }

      if(ingredient.had_variation == 1 && ingredient.ingredient_variations){
        ingredientInfo.hadVariations = true;
        const variations = JSON.parse(ingredient.ingredient_variations);
        for(let i = 0; i < variations.length; i++){
          const variation = variations[i];
          const variationInfo = {
            'nameEn': variation.variation_name_en,
            'nameZh': variation.variation_name_zh,
            'options': [],
          };
          for(let a = 0; a < variation.options.length; a++){
            const option =  variation.options[a];
            const optionInfo = {
              'optionNameEn': option.option_name_en,
              'optionNameZh': option.option_name_zh,
              'optionPrice': option.option_price,
              'optionQuantity': option.option_quantity,
            }
            if(option.option_quantity){
              optionInfo.unlimitedQuantity = false;
            }else{
              optionInfo.unlimitedQuantity = true;
            }
            variationInfo.options.push(optionInfo);
          }
          ingredientInfo.variations.push(variationInfo);
        }
      }else{
        ingredientInfo.hadVariations = false;
      }

      //Load proudct image
      const ingredientImages = JSON.parse(ingredient.ingredient_images);
      for(let i = 0; i < ingredientImages.length; i++){
        const imageUrl = ingredientImages[i];
        if(imageUrl){
          const imageFileName = imageUrl.substring(imageUrl.lastIndexOf('/')+1);
          const imageFile = {
            name: imageFileName,
            url: imageUrl,
          };
          ingredientInfo.ingredientImages.push(imageUrl);
        }
      }
      return ingredientInfo;
    },
    getIngredientCategoires(ingredient){
      const ingredientCategories = [];
      for(let i = 0; i < ingredient.ingredient_categories.length; i++){
        const ingredientCategorie = ingredient.ingredient_categories[i];
        if(ingredientCategorie){
          ingredientCategories.push(ingredientCategorie.name);
        }
      }
      const ingredientCategoriesString = ingredientCategories.join(', ');
      if(!ingredientCategoriesString){
        return '';
      }
      return ingredientCategoriesString
    },
    async loadIngredients(){
      try{
        const loginInfo = Common.getLoginInfo();
        const ingredients = await Ingredient.loadAllIngredients(this.apiUrl, loginInfo);
        this.orignalIngredients = ingredients;
        this.ingredients = ingredients;
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
   langcode: state => state.langcode,
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
.ingredient-image-wrapper{
  width: 75px;
  height: 75px;
  position: relative;
  overflow: hidden;
  .ingredient-image{
    overflow: hidden;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.ingredient-image-td{
  width: 20%;
}

.ingredient-quantity-editor{
  display: flex;
  .el-input, .form-control{
    max-width: 20%;
    margin-right: 50px;
  }
}
</style>
